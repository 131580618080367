import React from "react";

import styles from "./styles.module.scss";
import filtersStyles from "../styles.module.scss";

import { ReactComponent as CalendarIcon } from "../../../../assets/icons/calendar.svg";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import classNames from "classnames";
import { IFilterItem } from "../../../../utils/types/shared";

interface IFilterDate extends IFilterItem {
  setIsDisabledClose: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
}

export default function FilterDate({
  title,
  setIsDisabledClose,
  formState,
  setFormState,
  isHiddenFields,
}: IFilterDate) {
  function onDateFromChange(val: any) {
    setFormState({ ...formState, dateFrom: val?.$d?.getTime() || "" });
  }
  function onDateToChange(val: any) {
    setFormState({ ...formState, dateTo: val?.$d?.getTime() || "" });
  }
  return (
    <div
      className={classNames(filtersStyles.item, {
        [filtersStyles.hidden]: isHiddenFields,
      })}
    >
      <div className={filtersStyles.itemHead}>
        <CalendarIcon />
        <div>{title}:</div>
      </div>
      <div className={styles.grid}>
        <DateTimePicker
          localeText={{
            fieldYearPlaceholder: () => "ГГ",
            fieldMonthPlaceholder: () => "ММ",
            fieldDayPlaceholder: () => "ДД",
            fieldHoursPlaceholder: () => "ЧЧ",
            fieldMinutesPlaceholder: () => "ММ",
            okButtonLabel: "Применить",
            cancelButtonLabel: "Отмена",
            toolbarTitle: "Выберите дату и время",
          }}
          label={"От"}
          defaultValue={
            formState?.dateFrom ? dayjs(+formState?.dateFrom) : null
          }
          className={styles.datePicker}
          onOpen={() => setIsDisabledClose(true)}
          onClose={() => setIsDisabledClose(false)}
          onChange={onDateFromChange}
        />
        <DateTimePicker
          localeText={{
            fieldYearPlaceholder: () => "ГГ",
            fieldMonthPlaceholder: () => "ММ",
            fieldDayPlaceholder: () => "ДД",
            fieldHoursPlaceholder: () => "ЧЧ",
            fieldMinutesPlaceholder: () => "ММ",
            okButtonLabel: "Применить",
            cancelButtonLabel: "Отмена",
            toolbarTitle: "Выберите дату и время",
          }}
          label={"До"}
          defaultValue={formState?.dateTo ? dayjs(+formState?.dateTo) : null}
          className={styles.datePicker}
          onOpen={() => setIsDisabledClose(true)}
          onClose={() => setIsDisabledClose(false)}
          onChange={onDateToChange}
        />
      </div>
    </div>
  );
}
