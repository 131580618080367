import React, { useEffect } from "react";

import styles from "./styles.module.scss";

import classNames from "classnames";
import Tooltip from "@mui/material/Tooltip";
import Reserve from "./Reserve/Reserve";
import TokenBlock from "./TokenBlock/TokenBlock";
import useLogOut from "../../utils/hooks/useLogOut";
import Title from "../../components/typography/Title/Title";
import SbpBlock from "./SbpBlock/SbpBlock";
import C2CBlock from "./C2CBlock/C2CBlock";
import Amount from "../../components/shared/Amount/Amount";
import ReserveAdd from "./ReserveAdd/ReserveAdd";
import { ReactComponent as LogOutIcon } from "../../assets/icons/log-out-01.svg";
import { motion } from "framer-motion";
import { Skeleton } from "@mui/material";
import { useGetUserInfoQuery } from "../../store/user/user.api";
import { useGetPayoutLimitsQuery } from "../../store/payoutLimits/payoutLimits.api";
import { motionButtonProps } from "../../utils/styles/motionSettings";
import { IPayoutLimitItem } from "../../store/payoutLimits/payoutLimits.types";
import AccountBlock from "./AccountBlock/AccountBlock";
import { useGetExecutorQuery } from "../../store/executor/executor.api";

function compare(a: IPayoutLimitItem, b: IPayoutLimitItem) {
  if (a.bank_name < b.bank_name) {
    return -1;
  }
  if (a.bank_name > b.bank_name) {
    return 1;
  }
  return 0;
}

export default function ProfilePage() {
  const { data: userData, isLoading: isUserLoading } = useGetUserInfoQuery();
  const { data: execData } = useGetExecutorQuery();
  const { data } = useGetPayoutLimitsQuery();

  const logOut = useLogOut();

  return (
    <div className={styles.page}>
      <section>
        <div className={styles.amountPayouts}>
          <h3>Доступный объем выплат</h3>
          <p>
            <Amount
              value={userData?.data.payouts_total_limit || "0"}
              postfix="RUB"
            />
          </p>
        </div>
      </section>
      <Title>Управление аккаунтом</Title>
      <section className={styles.section}>
        <div className={classNames(styles.paper, styles.userBlock)}>
          <p className={styles.userName}>
            {isUserLoading ? (
              <Skeleton width={150} height={30} />
            ) : (
              userData?.data.name
            )}
          </p>
          <Tooltip title="Выход">
            <motion.button
              onClick={logOut}
              className={styles.logOut}
              {...motionButtonProps}
            >
              <LogOutIcon />
            </motion.button>
          </Tooltip>
        </div>
      </section>
      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>Резервы</h2>
        {data?.data
          .slice()
          .sort(compare)
          .map((i) => (
            <Reserve data={i} key={i.id} />
          ))}
        <ReserveAdd />
      </section>
      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>Настройки токена</h2>
        <TokenBlock />
        <SbpBlock />
        <C2CBlock />
        <AccountBlock />
      </section>
    </div>
  );
}
