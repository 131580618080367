import React, { useLayoutEffect } from "react";

import "./App.scss";
import "react-toastify/dist/ReactToastify.css";

import AuthPage from "./pages/AuthPage/AuthPage";
import Layout from "./components/layout/Layout";
import OrderPage from "./pages/OrderPage/OrderPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import OrdersPage from "./pages/OrdersPage/OrdersPage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import AutoOrderPage from "./pages/AutoOrderPage/AutoOrderPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { useDispatch } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { setFile } from "./store/files/files.slice";
import { ToastContainer } from "react-toastify";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0052ff",
    },
  },
});

function App() {
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.onmessage = function (event) {
        const file = event.data.file;
        dispatch(setFile(file));
      };
    }
  }, [navigator.serviceWorker]);

  return (
  <>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
          <ThemeProvider theme={theme}>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<OrderPage />} />
                <Route path="/auto-order/:id" element={<AutoOrderPage />} />
                <Route path="/upload" element={<></>} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/orders" element={<OrdersPage />} />
                <Route path="*" element={<NotFoundPage />} />
              </Route>
              <Route path="/auth" element={<AuthPage />} />
            </Routes>
          </ThemeProvider>
        </LocalizationProvider>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
