import React from "react";

import styles from "./styles.module.scss";

import Copy from "../../../components/shared/Copy/Copy";
import Avatar from "../../../components/shared/Avatar/Avatar";
import parsePhoneNumber from "libphonenumber-js";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check-circle.svg";
import { ReactComponent as XIcon } from "../../../assets/icons/x-circle.svg";
import { ReactComponent as CardIcon } from "../../../assets/icons/credit-card-upload.svg";
import { ReactComponent as EyeIcon } from "../../../assets/icons/eye.svg";
import { ReactComponent as FileIcon } from "../../../assets/icons/file-05.svg";
import { motion } from "framer-motion";
import { IOrderItem } from "../../../store/orders/orders.types";
import { useGetBanksListQuery } from "../../../store/payoutLimits/payoutLimits.api";
import { parseDate } from "../../../utils/helpers/parseDate";
import { transformCurrencyValue } from "../../../utils/helpers/transformCurrencyValue";
import { paymentColorFullIcons } from "../../../utils/constants/paymentColorFulIcons";
import { formatCardNumber } from "../../../utils/helpers/formatCardNumber";
import { Img } from "react-image";
import { Skeleton } from "@mui/material";
import { ReactComponent as SbpIcon } from "../../../assets/icons/sbp.svg";

interface IOrderItemProps {
  isOpen: boolean;
  onOpenClick: () => void;
  data: IOrderItem;
}

function getRemainingTime(targetDate: Date): string {
  const now = new Date();
  const diff = targetDate.getTime() - now.getTime();

  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

  return `${hours} ч ${minutes} мин`;
}

export function getTypeData(state: string) {
  switch (state) {
    case "completed":
      return {
        type: "success",
        title: "Заявка оплачена",
        description: "Платеж прошел проверку",
        icon: <CheckIcon />,
      };
    case "canceled":
      return {
        type: "danger",
        title: "Заявка отменена",
        description: "Вы отменили заявку",
        icon: <XIcon />,
      };
    case "expired":
      return {
        type: "danger",
        title: "Время истекло",
        description: "Истекло время на оплату",
        icon: <XIcon />,
      };
    case "verification_failed":
      return {
        type: "danger",
        title: "Не оплачена",
        description: "Проверьте чек",
        icon: <XIcon />,
      };
    case "verification":
      return {
        type: "warning",
        title: "Проверка платежа",
        description: "Система сканирует чек об операции",
        icon: <EyeIcon />,
      };
    case "manual_verification":
      return {
        type: "warning",
        title: "Проверка администрацией",
        description: "Платеж на проверке",
        icon: <EyeIcon />,
      };
    case "pending":
    case "processing":
      return {
        type: "accent",
        title: "Ожидает оплаты",
        icon: <CardIcon />,
      };
    case "paid":
      return {
        type: "purple",
        title: "Ожидает загрузки чека",
        icon: <FileIcon />,
      };
    default:
      return {
        type: "muted",
        title: "Ошибка: заявка не распознана",
        icon: <XIcon />,
      };
  }
}

export default function OrderItem({
  isOpen,
  onOpenClick,
  data,
}: IOrderItemProps) {
  const { data: banksData, isLoading: isBanksLoading } = useGetBanksListQuery({
    regionId: 1,
  });
  let bank;
  if (banksData?.data) {
    bank = banksData.data.find((i) => i.alias === data.bank_name);
  }

  function onItemClick() {
    if (isOpen) {
      return;
    }
    onOpenClick();
  }

  const typeData = getTypeData(data.state);

  return (
    <div className={styles.item} onClick={onItemClick}>
      <div className={styles.itemTop}>
        <div className={styles.itemStatus}>
          <Avatar type={typeData.type as "accent"} mode="square">
            {typeData.icon}
          </Avatar>
          <div className={styles.textBlock}>
            <div className={styles.statusTitle}>{typeData.title}</div>
            <div className={styles.statusDescription}>
              Создана: {parseDate(data.created_at, "numeric")}
            </div>
          </div>
        </div>
        <div className={styles.amount}>
          {data.amount && transformCurrencyValue(+data.amount)}{" "}
          {data?.currency?.toUpperCase()}
        </div>
      </div>
      {data.state === "paid" && data.expire_at && (
        <p className={styles.remainingTime}>
          Осталось: {getRemainingTime(new Date(data.expire_at))} на загрузку
          чека
        </p>
      )}
      <div className={styles.info}>
        <div className={styles.bottom}>
          <div className={styles.requisites}>
            <div className={styles.bank}>
              <Img
                className={styles.bankLogo}
                src={bank?.logo || ""}
                alt="Bank logo"
                loader={<Skeleton width={20} height={20} variant="rounded" />}
              />
              <span>
                {isBanksLoading ? <Skeleton width={42} /> : bank?.name}
              </span>
            </div>
            <div className={styles.paySystem}>
              {data.sbp ? (
                <SbpIcon />
              ) : (
                paymentColorFullIcons[data.card_brand as "visa"] || ""
              )}
              <p>
                {data.sbp
                  ? parsePhoneNumber(data.requisites)?.formatNational()
                  : formatCardNumber(data.requisites)}
              </p>
            </div>
          </div>
        </div>
        {
          <motion.div
            initial={{ maxHeight: 0 }}
            animate={{ maxHeight: isOpen ? 200 : 0 }}
            className={styles.dropContent}
          >
            <div className={styles.block}>
              <div className={styles.blockTitle}>ID заявки</div>
              <div className={styles.idContainer}>
                <span>{data.uuid}</span>{" "}
                <div className={styles.copy}>
                  <Copy value={data.uuid} />
                </div>
              </div>
            </div>

            {data.proof && (
              <div className={styles.block}>
                <div className={styles.blockTitle}>Справка о платеже</div>
                <div className={styles.receipt}>
                  <div className={styles.receiptContainer}>
                    <FileIcon />
                    <span>{data.proof.filename}</span>
                  </div>{" "}
                  <a href={data.proof.url}>Открыть файл -{">"}</a>
                </div>
              </div>
            )}
          </motion.div>
        }
      </div>
    </div>
  );
}
