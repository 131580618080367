import React from "react";

import styles from "./styles.module.scss";

import { IOrderItem } from "../../../store/orders/orders.types";
import { useMakePaidMutation } from "../../../store/orders/orders.api";
import ModalBase, { IModalBase } from "../ModalBase/ModalBase";
import dayjs from "dayjs";
import Button from "../../controls/Button/Button";
import showToast from "../../../utils/helpers/toast/showToast";
import Text from "../../typography/Text/Text";

interface IModalMakePaid extends IModalBase {
  data?: IOrderItem;
}

export default function ModalMakePaid({ data, ...rest }: IModalMakePaid) {
  const [makePaid, { isLoading, error }] = useMakePaidMutation();
  async function onSubmit() {
    if (!data?.id) {
      return showToast({
        description: "Не найден id",
        type: "error",
        value: "Ошибка",
      });
    }
    try {
      await makePaid({ id: data?.id }).unwrap();
      rest.onClose();
    } catch (error) {}
  }

  return (
    <ModalBase {...rest}>
      <div className={styles.container}>
        <div className={styles.content}>
          <h2 className={styles.title}>Не получили чек об оплате?</h2>
          <p className={styles.description}>
            Нажмите кнопку <b>Загрузить позднее</b> и тогда MassMo будет считать
            ваш платеж временно выполненным.
          </p>
          <p className={styles.warning}>
            В случае подтверждения, вам необходимо приложить чек в течение 12
            часов (до {dayjs(data?.expire_at).format("HH:mm DD.MM")}), иначе мы
            отменим выплату
          </p>
        </div>
        {error && <Text type="danger">{JSON.stringify(error)}</Text>}
        <Button withAnimation size="lg" loading={isLoading} onClick={onSubmit}>
          Загрузить позднее
        </Button>
      </div>
    </ModalBase>
  );
}
