import React, { useState } from "react";

import styles from "./styles.module.scss";

import ModalBase, { IModalBase } from "../ModalBase/ModalBase";
import { useGetBanksListQuery } from "../../../store/payoutLimits/payoutLimits.api";

interface IModalSelectBank extends IModalBase {
  selectedBankName: string;
  setSelectedBankName(val: string): void;
}

export default function ModalSelectBank(props: IModalSelectBank) {
  const { selectedBankName, setSelectedBankName, ...rest } = props;

  const { data: banksData, isLoading: isBanksLoading } = useGetBanksListQuery({
    regionId: 1,
  });

  let bank;
  if (banksData?.data) {
    bank = banksData.data.find((i) => i.alias === "");
  }

  return (
    <ModalBase {...rest}>
      <div className={styles.container}>
        <div className={styles.head}>
          <h2>Банк для оплаты</h2>
          <p>Выберите банк для оплаты заявки</p>
        </div>
        <div className={styles.selectContainer}>
          <div className={styles.selectList}>
            {banksData?.data.map((i) => (
              <button
                onClick={() => {
                  setSelectedBankName(i.alias);
                  rest.onClose();
                }}
                className={styles.selectItem}
                key={i.alias}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="19"
                    height="19"
                    rx="9.5"
                    fill="#F9FAFB"
                    stroke="#EEF2F9"
                  />
                  {i.alias === selectedBankName && (
                    <rect
                      x="4"
                      y="4"
                      width="12"
                      height="12"
                      rx="6"
                      fill="#0052FF"
                    />
                  )}
                </svg>
                <div className={styles.itemBank}>
                  <div className={styles.bankLogo}>
                    <img src={i?.logo} alt="logo" />
                  </div>
                  <p>{i.name}</p>
                </div>
              </button>
            ))}
          </div>
          {/* <Button size="lg">Подтвердить</Button> */}
        </div>
      </div>
    </ModalBase>
  );
}
