import React from "react";

import styles from "./styles.module.scss";

import ModalBase, { IModalBase } from "../ModalBase/ModalBase";
import Status from "../../shared/Status/Status";
import ModalContainer from "../ModalBase/ModalContainer/ModalContainer";
import Button from "../../controls/Button/Button";
import { ReactComponent as EyeIcon } from "../../../assets/icons/eye-off.svg";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { motionButtonProps } from "../../../utils/styles/motionSettings";

export default function ModalEmptyNext(props: IModalBase) {
  const navigate = useNavigate();
  return (
    <ModalBase {...props}>
      <ModalContainer>
        <Status
          icon={<EyeIcon />}
          type="accent"
          title="Нет активных заявок"
          description="Система не смогла подобрать заявку под ваши параметры аккаунта."
        />
        <div className={styles.buttons}>
          <motion.div className={styles.buttonWrapper} {...motionButtonProps}>
            <Button
              size="lg"
              type="muted"
              onClick={() => {
                props.onClose();
                navigate("/profile");
              }}
            >
              Параметры аккаунта
            </Button>
          </motion.div>

          <motion.div className={styles.buttonWrapper} {...motionButtonProps}>
            <Button size="lg" onClick={props.onClose}>
              Закрыть
            </Button>
          </motion.div>
        </div>
      </ModalContainer>
    </ModalBase>
  );
}
