import React, { useMemo } from "react";

import styles from "./styles.module.scss";

import { ReactComponent as CancelIcon } from "../../../../assets/icons/cancel.svg";
import { ReactComponent as FileIcon } from "../../../../assets/icons/file.svg";
import { ReactComponent as ImageIcon } from "../../../../assets/icons/Image.svg";
import { ReactComponent as FilePdfIcon } from "../../../../assets/icons/file-pdf.svg";
import classNames from "classnames";
import Text from "../../../typography/Text/Text";
import { humanFileSize } from "../../../../utils/helpers/humanFileSize";

export default function UploaderItem({
  file,
  removeFile,
}: {
  file: File;
  removeFile(): void;
}) {
  function getExtension(file: File) {
    let splittedName = file.name.split(".");

    return "." + splittedName[splittedName.length - 1];
  }
  const extension = useMemo(() => getExtension(file), [file]);
  function switchIcon(extension: string) {
    switch (extension) {
      case ".pdf":
        return <FilePdfIcon />;
      case ".png":
        return <ImageIcon />;
      case ".jpg":
        return <ImageIcon />;
      case ".jpeg":
        return <ImageIcon />;
      default:
        return <FileIcon />;
    }
  }
  let name = file.name;
  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.avatar, {
          [styles.danger]: extension === ".pdf",
        })}
      >
        {switchIcon(extension)}{" "}
        <button onClick={() => removeFile()} className={styles.close}>
          <CancelIcon width={8} height={8} />
        </button>
      </div>
      <div className={styles.info}>
        {file.name.length > 14 ? (
          <p className={styles.name}>
            {file.name.slice(0, 10)}...{" "}
            <Text type={extension === ".pdf" ? "danger" : "accent"}>
              {extension}
            </Text>
          </p>
        ) : (
          <p className={styles.name}>
            {name?.split(".").slice(0, -1).join(".")}
            <Text type={extension === ".pdf" ? "danger" : "accent"}>
              {extension}
            </Text>
          </p>
        )}
        <span className={styles.otherInfo}>{humanFileSize(file.size)} </span>
      </div>
    </div>
  );
}
