import React, { useState } from "react";

import styles from "./styles.module.scss";

import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x-close.svg";
import { ReactComponent as BankIcon } from "../../../assets/icons/bank.svg";
import { ReactComponent as ChevronIcon } from "../../../assets/icons/chevron-down-wrapped.svg";
import { motion } from "framer-motion";
import { motionButtonProps } from "../../../utils/styles/motionSettings";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { transformCurrencyValue } from "../../../utils/helpers/transformCurrencyValue";
import { useCreatePayoutLimitMutation } from "../../../store/payoutLimits/payoutLimits.api";
import showToast from "../../../utils/helpers/toast/showToast";
import classNames from "classnames";
import Button from "../../../components/controls/Button/Button";
import Text from "../../../components/typography/Text/Text";
import CurrencyInput from "react-currency-input-field";
import useGetBank from "../../../utils/hooks/useGetBank";
import ModalSelectBank from "../../../components/modals/ModalSelectBank/ModalSelectBank";

const MIN_AMOUNT = 0;

interface IForm {
  limit: number;
  bank_name: string;
}

export default function ReserveAdd() {
  const {
    handleSubmit,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm<IForm>({ mode: "onChange" });

  const [searchParams, setSearchParams] = useSearchParams();
  const [addMode, setAddMode] = useState(false);
  const [createPayoutLimit, { error, isLoading }] =
    useCreatePayoutLimitMutation();

  const selectedBank = useGetBank({ bankAlias: watch("bank_name") });

  function openSelectBank() {
    searchParams.set("bank_modal", "1");
    setSearchParams(searchParams);
  }

  async function onSubmit(data: IForm) {
    try {
      await createPayoutLimit(data).unwrap();
      setAddMode(false);
      showToast({
        type: "success",
        value: "Успех",
        description: "Резерв успешно добавлен",
      });
    } catch (e) {
      showToast({
        type: "error",
        value: "Ошибка",
        description: "Произошла ошибка при создании резерва",
      });
    }
  }

  return (
    <>
      <ModalSelectBank
        selectedBankName={watch("bank_name")}
        setSelectedBankName={(name: string) => setValue("bank_name", name)}
        isOpen={Boolean(searchParams.get("bank_modal"))}
        onClose={() => {
          searchParams.delete("bank_modal");
          setSearchParams(searchParams);
        }}
      />
      {addMode ? (
        <form className={styles.addForm} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.head}>
            <button
              type="button"
              onClick={openSelectBank}
              className={styles.selectBank}
            >
              <div
                className={classNames(styles.bankLogo, {
                  [styles.error]: !selectedBank && errors?.bank_name,
                })}
              >
                {selectedBank ? (
                  <img src={selectedBank?.logo} alt="logo" />
                ) : (
                  <BankIcon />
                )}
              </div>
              <span
                disabled
                {...register("bank_name", { required: "Банк не выбран" })}
              >
                {selectedBank?.name
                  ? selectedBank?.name
                  : (errors?.bank_name?.message && (
                      <Text type="danger"> {errors?.bank_name?.message}</Text>
                    )) ||
                    "Выберите банк"}
              </span>

              <div className={styles.chevron}>
                <ChevronIcon />
              </div>
            </button>

            <motion.button
              className={styles.close}
              onClick={() => setAddMode(false)}
              {...motionButtonProps}
            >
              <CloseIcon />
            </motion.button>
          </div>

          <div
            className={classNames(styles.reserveLeft, {
              [styles.error]: Boolean(errors?.limit),
            })}
          >
            <h4 className={styles.reserveTitle}>
              {errors?.limit?.message ? (
                <Text type="danger"> {errors?.limit?.message}</Text>
              ) : (
                "Резерв"
              )}
            </h4>
            <div className={styles.reserveBalance}>
              <span className={styles.reserveCurrency}>RUB</span>
              <CurrencyInput
                className={styles.reserveAmount}
                onValueChange={(value) => setValue("limit", Number(value))}
                {...register("limit", {
                  required: "Резерв не заполнен",
                  min: {
                    value: MIN_AMOUNT,
                    message: `Минимальное значение ${transformCurrencyValue(
                      MIN_AMOUNT
                    )}`,
                  },
                })}
              />
            </div>
          </div>

          {error && <Text type="danger"> {JSON.stringify(error)}</Text>}

          <motion.div {...motionButtonProps} className={styles.submitWrapper}>
            <Button loading={isLoading} size="lg">
              Добавить резерв
            </Button>
          </motion.div>
        </form>
      ) : (
        <motion.button
          className={styles.addReserve}
          onClick={() => setAddMode(true)}
          {...motionButtonProps}
        >
          <PlusIcon /> Добавить новый резерв
        </motion.button>
      )}
    </>
  );
}
