import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query";
import { loggedOut } from "./auth/auth.slice";
import showToast from "../utils/helpers/toast/showToast";

const baseQueryWithToken = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
      headers.set("X-REGION-ID", `${localStorage.getItem("regionId")}`);
    }
    return headers;
  },
});
const baseQueryWithTokenWrapper: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQueryWithToken(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    api.dispatch(loggedOut());
  } else if (
    result?.error &&
    result.error.status !== 404 &&
    // @ts-ignore
    !result?.error?.data?.errors?.executor?.includes(
      "cannot assign order to disabled executor"
    )
  ) {
    showToast({
      value: `Ошибка ${result.error.status}`,
      description: "Запрос не выполнен",
      type: "error",
    });
  }
  return result;
};

export default baseQueryWithTokenWrapper;
