import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";

import { motion } from "framer-motion";
import { useSearchParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { IOrderItem } from "../../../store/orders/orders.types";
import { useCompleteOrderMutation } from "../../../store/orders/orders.api";
import { motionButtonProps } from "../../../utils/styles/motionSettings";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { deleteFile, selectFile } from "../../../store/files/files.slice";
import {
  selectExecutorBankName,
  setExecutorBankName,
} from "../../../store/orders/orders.slice";
import Text from "../../../components/typography/Text/Text";
import Uploader from "../../../components/controls/Uploader/Uploader";
import ModalSelectBank from "../../../components/modals/ModalSelectBank/ModalSelectBank";
import ModalOrderAccept from "../../../components/modals/ModalOrderAccept/ModalOrderAccept";
import CancelBlock from "./CancelBlock/CancelBlock";
import AmountBlock from "../AmountBlock/AmountBlock";
import RequisiteBlock from "./RequisiteBlock/RequisiteBlock";
import DetailsBlock from "./DetailsBlock/DetailsBlock";
import BankChose from "./BankChose/BankChose";
import useGetBank from "../../../utils/hooks/useGetBank";
import NextOrderButton from "./NextOrderButton/NextOrderButton";
import AutoPayoutButton from "./AutoPayoutButton/AutoPayoutButton";
import ProcessingWrapper from "./ProcessingWrapper/ProcessingWrapper";
import VerificationFailedBlock from "./VerificationFailedBlock/VerificationFailedBlock";

interface IOrderContent {
  data: IOrderItem;
  verifyMode: boolean;
  setVerifyMode: (value: React.SetStateAction<boolean>) => void;
}

export default function OrderContent({
  data,
  setVerifyMode,
  verifyMode,
}: IOrderContent) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [files, setFiles] = useState<any[]>([]);
  const [customError, setCustomError] = useState<string>();
  const [bankError, setBankError] = useState<string>();
  const dispatch = useAppDispatch();
  const file = useAppSelector(selectFile);
  const selectedBankName = useAppSelector(selectExecutorBankName);

  useEffect(() => {
    file && setFiles([file]);
  }, [file]);

  const [completeOrder, { isLoading: isCompleteLoading, error }] =
    useCompleteOrderMutation();
  const bank = useGetBank({ bankAlias: data.bank_name });
  const selectedBank = useGetBank({ bankAlias: selectedBankName });
  const isProcessing = data.state === "processing";

  async function onSubmit() {
    if (!selectedBankName) {
      setCustomError("Выберите банк для оплаты");
      return setBankError("Выберите банк для оплаты");
    }
    try {
      await completeOrder({
        files,
        id: data.id,
        senderBankName: selectedBankName,
      }).unwrap();
      setSearchParams(searchParams);
      setFiles([]);
      dispatch(deleteFile());
      setVerifyMode(true);
    } catch (error) {}
  }

  useEffect(() => {
    const responseError: any = error;
    if (
      responseError?.data?.errors?.proof &&
      responseError?.data?.errors?.proof[0] === "invalid format"
    ) {
      setCustomError("Этот формат файла не поддерживается");
    } else if (
      responseError?.data?.errors?.proof &&
      responseError?.data?.errors?.proof[0] === "must be less than 3 MB"
    ) {
      setCustomError("Максимальный вес файла: 3МБ");
    } else {
      setCustomError(JSON.stringify(error));
    }
  }, [error]);
  useEffect(() => {
    setCustomError("");
  }, [files]);

  return (
    <div className={styles.container} style={{ gap: isProcessing ? 30 : 10 }}>
      <ModalOrderAccept
        isOpen={Boolean(searchParams.get("auto_payout_modal"))}
        onClose={() => {
          searchParams.delete("auto_payout_modal");
          setSearchParams(searchParams);
        }}
        id={searchParams.get("auto_payout_modal") || ""}
      />
      <ModalSelectBank
        selectedBankName={selectedBankName}
        setSelectedBankName={(name: string) =>
          dispatch(setExecutorBankName(name))
        }
        isOpen={Boolean(searchParams.get("bank_modal"))}
        onClose={() => {
          searchParams.delete("bank_modal");
          setSearchParams(searchParams);
        }}
      />

      {data.payment_functionality_visible && <AutoPayoutButton data={data} />}
      {isProcessing ? (
        <ProcessingWrapper
          title={
            <>
              <Text type="muted">1.</Text> Переведите ровно:
            </>
          }
        >
          <AmountBlock
            isProcessing={isProcessing}
            amount={data.amount}
            currency={data.currency}
          />
        </ProcessingWrapper>
      ) : (
        <AmountBlock
          isProcessing={isProcessing}
          amount={data.amount}
          currency={data.currency}
        />
      )}
      {data.payment_functionality_visible &&
        (isProcessing ? (
          <ProcessingWrapper
            title={
              <>
                <Text type="muted">2.</Text> Строго по реквизитам в указанном
                банке:
              </>
            }
          >
            <RequisiteBlock
              bank={bank as any}
              data={data}
              isProcessing={isProcessing}
            />
          </ProcessingWrapper>
        ) : (
          <RequisiteBlock
            bank={bank as any}
            data={data}
            isProcessing={isProcessing}
          />
        ))}
      {data.payment_functionality_visible && (
        <>
          {isProcessing ? (
            <ProcessingWrapper
              title={
                <>
                  <Text type="muted">3.</Text> После оплаты выберите банк, с
                  которого оплатили:
                </>
              }
            >
              <BankChose
                isProcessing={isProcessing}
                bankError={bankError}
                selectedBankName={selectedBankName}
                selectedBank={selectedBank}
              />
            </ProcessingWrapper>
          ) : (
            <BankChose
              isProcessing={isProcessing}
              bankError={bankError}
              selectedBankName={selectedBankName}
              selectedBank={selectedBank}
            />
          )}
          <div className={styles.uploaderBlock}>
            {isProcessing ? (
              <ProcessingWrapper
                title={
                  <>
                    <Text type="muted">4.</Text> После оплаты загрузите чек об
                    операции:
                  </>
                }
              >
                <Uploader
                  files={files}
                  setFiles={setFiles}
                  verifyMode={verifyMode}
                />
              </ProcessingWrapper>
            ) : (
              <Uploader
                files={files}
                setFiles={setFiles}
                verifyMode={verifyMode}
              />
            )}

            {customError && <Text type="danger">{customError}</Text>}
            {data.state === "verification_failed" && files.length === 0 && (
              <VerificationFailedBlock data={data} />
            )}
            {files.length === 0 && data.state === "processing" && (
              <motion.button
                {...motionButtonProps}
                className={styles.withoutReceipt}
                onClick={() => {
                  searchParams.set("make_paid_modal", "1");
                  setSearchParams(searchParams);
                }}
              >
                Оплатили, но не получили чек?
              </motion.button>
            )}
            {files.length > 0 && (
              <motion.button
                onClick={onSubmit}
                {...motionButtonProps}
                className={styles.submit}
                disabled={isCompleteLoading}
              >
                {isCompleteLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  "Отправить чек"
                )}
              </motion.button>
            )}
          </div>
        </>
      )}
      {data.is_final_state && <NextOrderButton />}

      <DetailsBlock data={data} />

      {!data.is_final_state && data.state !== "manual_verification" && (
        <CancelBlock
          onCancel={() => {
            searchParams.set("cancel_modal", "1");
            setSearchParams(searchParams);
          }}
        />
      )}
    </div>
  );
}
