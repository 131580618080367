import React from "react";

import styles from "../styles.module.scss";
import Copy from "../../../../components/shared/Copy/Copy";
import dayjs from "dayjs";

export default function DetailsBlock({
  data,
}: {
  data: { created_at: string; uuid: string };
}) {
  return (
    <section className={styles.details}>
      <h2>Дополнительная информация</h2>
      <div className={styles.copyBlock}>
        <div className={styles.left}>Дата создания:</div>
        <div className={styles.right}>
          <time>{dayjs(data.created_at).format("DD.MM.YY, в HH:mm")}</time>
          <Copy value={dayjs(data.created_at).format("DD.MM.YY, в HH:mm")} />
        </div>
      </div>
      <div className={styles.copyBlock}>
        <div className={styles.left}>ID выплаты:</div>
        <div className={styles.right}>
          <span>
            #{data.uuid?.slice(0, 8)}...{data.uuid?.slice(-5)}
          </span>
          <Copy value={data.uuid} />
        </div>
      </div>
    </section>
  );
}
