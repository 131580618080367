import React, { useState, useEffect } from "react";

import styles from "./styles.module.scss";
import formStyles from "../styles.module.scss";

import classNames from "classnames";
import { ReactComponent as OrderIcon } from "../../../../assets/icons/clock-refresh.svg";
import { IFilterItem, QueryType } from "../../../../utils/types/shared";
import DropDown from "../../../controls/DropDown/DropDown";

const initialStatuses = [
  {
    state: "",
    value: "Все сделки",
    selected: false,
  },
  {
    state: "completed",
    value: "Заявка оплачена",
    selected: false,
  },
  {
    state: "processing",
    value: "Заявка создана",
    selected: false,
  },
  {
    state: "canceled",
    value: "Заявка отменена",
    selected: false,
  },
  {
    state: "expired",
    value: "Время истекло",
    selected: false,
  },
];

export default function FilterDealType({
  formState,
  setFormState,
  isHiddenFields,
  setIsHiddenFields,
}: IFilterItem) {
  const [isDdActive, setIsDdActive] = useState(false);
  function onTypeChange(value: string, index?: number) {
    let selectedType: QueryType = "";
    if (index) {
      selectedType = initialStatuses[index].state;
    }
    setFormState({ state: selectedType });
  }

  useEffect(() => {
    setIsHiddenFields(isDdActive);
  }, [isDdActive]);
  return (
    <div
      className={classNames(formStyles.item, {
        [formStyles.hidden]: isHiddenFields && !isDdActive,
      })}
    >
      <div className={formStyles.itemHead}>
        <OrderIcon />
        <div>Статус заявок:</div>
      </div>
      <DropDown
        onChange={onTypeChange}
        size="md"
        items={initialStatuses.map((i) => i.value)}
        selected={
          formState?.state &&
          initialStatuses.find((i) => i.state === formState.state)?.value
        }
        selectedClassName={styles.ddSelected}
        onActiveChange={(bool: boolean) => setIsDdActive(bool)}
      />
    </div>
  );
}
