import React from "react";

import styles from "./styles.module.scss";

import { ReactComponent as Eye } from "../../../../assets/icons/eye.svg";
import { ReactComponent as Clock } from "../../../../assets/icons/clock.svg";
import { ReactComponent as File } from "../../../../assets/icons/file-05.svg";
import { ReactComponent as XClose } from "../../../../assets/icons/x-close.svg";
import { ReactComponent as Check } from "../../../../assets/icons/check.svg";
import { ReactComponent as CardLock } from "../../../../assets/icons/credit-card-lock.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { motionButtonProps } from "../../../../utils/styles/motionSettings";
import { IOrderItem } from "../../../../store/orders/orders.types";
import Status from "../../../../components/shared/Status/Status";
import dayjs from "dayjs";
import ErrorParser from "../../../../components/shared/ErrorParser/ErrorParser";

export default function StatusSwitcher({
  state,
  isReservesOver,
  isTokenEnabled,
  orderError,
  getNextOrder,
  nextOrderError,
  isNextOrderLoading,
  children,
  orderData,
}: {
  orderData?: IOrderItem;
  state: string;
  isTokenEnabled: boolean;
  isReservesOver: boolean;
  orderError: any;
  nextOrderError: any;
  getNextOrder: () => void;
  isNextOrderLoading: boolean;
  children?: React.ReactNode;
}) {
  if (isReservesOver) {
    return (
      <>
        <Status
          icon={<CardLock />}
          title="Резервы исчерпаны"
          description={
            <>
              Вы не можете оплатить заявку, <br />
              так как у вас недостаточно резервов
            </>
          }
          type="danger"
        />
        <div className={styles.buttonContainer}>
          <motion.button {...motionButtonProps}>
            <Link to={"/profile"} className={styles.button}>
              Изменить резервы
            </Link>
          </motion.button>
        </div>
      </>
    );
  }
  if (orderError?.status === 404) {
    return (
      <>
        <Status
          icon={<XClose />}
          title="У вас нет активной заявки на выплату"
          type="accent"
          description={
            <>
              Нажмите "получить заявку на выплату", <br />
              для запроса новой заявки
            </>
          }
        />

        <div className={styles.buttonContainer}>
          {nextOrderError && nextOrderError.status !== 404 && (
            <ErrorParser
              error={nextOrderError}
              translates={{
                keys: [
                  {
                    key: "base",
                    translate: "Ошибка",
                  },
                ],
                values: [
                  {
                    key: "token is suspended",
                    translate: "Токен приостановлен",
                  },
                ],
              }}
            />
          )}
          <motion.button
            {...motionButtonProps}
            className={styles.button}
            onClick={getNextOrder}
            disabled={isNextOrderLoading}
          >
            Получить заявку на выплату
          </motion.button>
        </div>
      </>
    );
  }
  if (orderError?.status) {
    return (
      <>
        <Status
          icon={<XClose />}
          title={`Ошибка ${orderError?.status}`}
          type="danger"
          description={orderError?.message}
        />
      </>
    );
  }
  if (!isTokenEnabled) {
    return (
      <>
        <Status
          icon={<XClose />}
          title="Токен отключен"
          description="Вы не получаете заявки на выплаты"
          type="danger"
        >
          {children}
        </Status>
        <div className={styles.buttonContainer}>
          <motion.button {...motionButtonProps}>
            <Link to={"/profile"} className={styles.button}>
              Управление аккаунтом
            </Link>
          </motion.button>
        </div>
      </>
    );
  }
  switch (state) {
    case "pending":
    case "processing":
      return (
        <h4 className={styles.processingTitle}>Заявка ожидает оплаты</h4>
        // <Status
        //   icon={<Clock />}
        //   title="Заявка ожидает оплаты"
        //   description="Совершите перевод и прикрепите чек"
        //   type="accent"
        // >
        //   {children}
        // </Status>
      );

    case "paid":
      return (
        <Status
          icon={<File />}
          type="purple"
          title="Заявка ожидает загрузки чека"
          description={`Загрузите чек об оплате до ${dayjs(
            orderData?.expire_at
          ).format("HH:mm DD.MM")}`}
        >
          {children}
        </Status>
      );
    case "verification":
      return (
        <Status
          icon={<Eye />}
          title="Проверяем ваш платеж"
          description="Система сканирует чек об операции"
          type="warning"
        >
          {children}
        </Status>
      );
    case "completed":
      return (
        <Status
          icon={<Check />}
          title="Заявка оплачена"
          description="Платеж прошел проверку"
          type="success"
        >
          {children}
        </Status>
      );
    case "expired":
      return (
        <Status
          icon={<XClose />}
          title="Заявка не была оплачена"
          description="Истекло время на оплату"
          type="danger"
        >
          {children}
        </Status>
      );
    case "verification_failed":
      return (
        <Status
          icon={<XClose />}
          title="Платеж не прошел проверку"
          description={
            <>
              Проверьте корректность чека <br /> и реквизиты получателя
            </>
          }
          type="danger"
        >
          {children}
        </Status>
      );
    case "manual_verification":
      return (
        <Status
          icon={<Eye />}
          title="Проверка администрацией"
          description={<>Платеж на проверке</>}
          type="warning"
        >
          {children}
        </Status>
      );
    case "canceled":
      return (
        <Status
          icon={<XClose />}
          title="Заявка отменена"
          description="Вы отменили данную заявку"
          type="danger"
        >
          {children}
        </Status>
      );
    default:
      return (
        <Status
          icon={<XClose />}
          title="Нераспознан статус заявки"
          description="Ошибка, не удалось определить статус заявки"
          type="danger"
        >
          {children}
        </Status>
      );
  }
}
