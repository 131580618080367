import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";

import { ParsedUrlQuery } from "querystring";
import { useSearchParams } from "react-router-dom";
import Filters from "../../../components/filters/Filters/Filters";
import FilterDealType from "../../../components/filters/Filters/FilterDealType/FilterDealType";
import FilterAmount from "../../../components/filters/Filters/FilterAmount/FilterAmount";
import FilterDate from "../../../components/filters/Filters/FilterDate/FilterDate";

export interface IOrdersPageFilters {
  formState: ParsedUrlQuery;
  setFormState: React.Dispatch<React.SetStateAction<ParsedUrlQuery>>;
  isHiddenFields: boolean;
  setIsHiddenFields: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialFormState = {
  state: "",
  amount: "",
  amountFrom: "",
  amountTo: "",
  dateFrom: "",
  dateTo: "",
};
export default function OrdersPageFilters({
  isDeviceHidden,
}: {
  isDeviceHidden?: boolean;
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [isActive, setActive] = useState(false);
  const [isHiddenFields, setIsHiddenFields] = useState(false);
  const [isDisabledClose, setIsDisabledClose] = useState(false);
  const [formState, setFormState] = useState(initialFormState);

  function onSubmit() {
    formState.dateFrom && searchParams.set("dateFrom", formState.dateFrom);
    formState.dateTo && searchParams.set("dateTo", formState.dateTo);
    formState.amountFrom &&
      searchParams.set("amountFrom", formState.amountFrom);
    formState.amountTo && searchParams.set("amountTo", formState.amountTo);
    formState.amount && searchParams.set("amount", formState.amount);
    formState.state && searchParams.set("state", formState.state);
    setSearchParams(searchParams);
  }

  useEffect(() => {
    const initialFormStateLabels: string[] = Object.keys(initialFormState);
    let routerState: any = {};
    for (let key in formState) {
      if (initialFormStateLabels.includes(key)) {
        const fieldValue = searchParams.get(key);
        if (fieldValue) {
          routerState = {
            ...routerState,
            [key]: searchParams.get(key) || "",
          };
        }
      }
    }
    setFormState(routerState);
  }, [searchParams]);

  const selectedCount = Object.values(formState).filter(
    (i) => i && i?.length > 0
  ).length;

  function onCancel() {
    setFormState(initialFormState);
    searchParams.delete("dateFrom");
    searchParams.delete("dateTo");
    setSearchParams(searchParams);
  }

  return (
    <Filters
      isActive={isActive}
      setActive={setActive}
      title={"Фильтр по заявкам"}
      placeholder={"Не выбраны"}
      onSubmit={onSubmit}
      onCancel={onCancel}
      isDisabledClose={isDisabledClose}
      value={selectedCount ? `Выбрано: ${selectedCount}` : undefined}
    >
      <FilterDealType
        isHiddenFields={isHiddenFields}
        setIsHiddenFields={setIsHiddenFields}
        formState={formState}
        setFormState={setFormState}
      />
      <FilterAmount
        isHiddenFields={isHiddenFields}
        setIsHiddenFields={setIsHiddenFields}
        formState={formState}
        setFormState={setFormState}
      />
      <FilterDate
        title="Дата получения"
        isHiddenFields={isHiddenFields}
        setIsHiddenFields={setIsHiddenFields}
        setIsDisabledClose={setIsDisabledClose}
        formState={formState}
        setFormState={setFormState}
      />
    </Filters>
  );
}
