import React, { useRef } from "react";

import styles from "./styles.module.scss";

import { ReactComponent as CancelIcon } from "../../../assets/icons/x-close.svg";
import { ReactComponent as Chevron } from "../../../assets/icons/chevron-down.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/settings-04.svg";
import { IWrapComponent } from "../../../utils/types/shared";
import classNames from "classnames";
import useMountTransition from "../../../utils/hooks/useMountTransition";
import useOnClickOutside from "../../../utils/hooks/useClickOutside";
import Button from "../../controls/Button/Button";

interface IFilters extends IWrapComponent {
  title?: string;
  value?: string;
  placeholder: string;
  onSubmit?: () => void;
  onCancel?: () => void;
  isActive: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabledClose?: boolean;
}

export default function Filters(props: IFilters) {
  const {
    title,
    value,
    children,
    placeholder,
    isActive,
    setActive,
    onSubmit,
    onCancel,
    isDisabledClose,
  } = props;

  const containerRef = useRef<null | HTMLDivElement>(null);
  const hasTransitionedIn = useMountTransition(isActive, 300);
  useOnClickOutside(containerRef, close);

  function cancel() {
    close();
    onCancel && onCancel();
  }
  function close() {
    !isDisabledClose && setActive(false);
  }
  function toggle() {
    setActive((isActive) => !isActive);
  }
  function submit() {
    onSubmit && onSubmit();
    setActive(false);
  }
  return (
    <div className={styles.wrapper}>
      {title && <div className={styles.title}>{title}</div>}
      <div ref={containerRef} className={styles.container}>
        <button onClick={toggle} className={styles.button}>
          <SettingsIcon className={styles.iconLeft} />
          {value ? (
            <span className={styles.selected}>{value}</span>
          ) : (
            <span className={classNames(styles.selected, styles.placeholder)}>
              {placeholder}
            </span>
          )}
          <Chevron
            className={classNames(styles.icon, { [styles.active]: isActive })}
          />
        </button>
        {(hasTransitionedIn || isActive) && (
          <div
            className={classNames(styles.content, {
              [styles.in]: hasTransitionedIn,
              [styles.active]: isActive,
            })}
          >
            <div className={styles.fields}>
              <div className={styles.fieldsHead}>
                <h4 className={styles.fieldsTitle}>Параметры поиска</h4>
                <button
                  onClick={() => setActive(false)}
                  className={styles.fieldsClose}
                >
                  <CancelIcon />
                </button>
              </div>
              {children}
            </div>

            <div className={styles.controls}>
              <Button onClick={cancel} size="sm" type="primary">
                Сбросить все
              </Button>

              <Button size="sm" onClick={submit}>
                Применить фильтры
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
