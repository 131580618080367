import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";
import profileStyles from "../styles.module.scss";

import { Skeleton, Tooltip } from "@mui/material";
import { useAppDispatch } from "../../../store/hooks";
import { ReactComponent as SbpIcon } from "../../../assets/icons/sbp.svg";
import {
  useChangeExecutorMutation,
  useGetExecutorQuery,
} from "../../../store/executor/executor.api";
import { ordersApi } from "../../../store/orders/orders.api";
import Text from "../../../components/typography/Text/Text";
import AppSwitch from "../../../components/shared/AppSwitch/AppSwitch";
import classNames from "classnames";

export default function SbpBlock() {
  const dispatch = useAppDispatch();
  const [isSbpEnabled, setSbpEnabled] = useState(true);
  const { data: executorData, isLoading: isExecutorLoading } =
    useGetExecutorQuery();
  const [changeExecutor] = useChangeExecutorMutation();

  const handleChange = async () => {
    setSbpEnabled((state) => !state);
    if (executorData?.data?.state) {
      try {
        await changeExecutor({ accepts_sbp: !executorData.data.accepts_sbp });
        dispatch(ordersApi.util.resetApiState());
      } catch {
        setSbpEnabled((state) => !state);
      }
    }
  };

  useEffect(() => {
    if (executorData?.data?.state) {
      setSbpEnabled(executorData?.data?.accepts_sbp);
    }
  }, [executorData]);

  function getTokenSwitchText() {
    if (isExecutorLoading) {
      return <Skeleton height={19} width={128} />;
    }
    if (isSbpEnabled) {
      return <Text type="accent">Выплаты по СБП</Text>;
    }
    return <Text type="muted">Выплаты по СБП</Text>;
  }

  function getTokenDescription() {
    if (isExecutorLoading) {
      return <Skeleton width={"100%"} height={42} />;
    }
    if (isSbpEnabled) {
      return "Включите, чтобы принимать заявки на выплаты по СБП";
    }
    return "Включите, чтобы принимать заявки на выплаты по СБП";
  }
  return (
    <div className={classNames(profileStyles.paper, styles.container)}>
      <div className={styles.tokenSwitchContainer}>
        <Tooltip title={isSbpEnabled ? "Отключить" : "Включить"}>
          <AppSwitch
            disabled={isExecutorLoading}
            checked={isSbpEnabled}
            onChange={handleChange}
          />
        </Tooltip>
        <p className={styles.tokenSwitchText}>
          {getTokenSwitchText()} <SbpIcon />
        </p>
      </div>
      <p className={styles.tokenDescription}>{getTokenDescription()}</p>
    </div>
  );
}
