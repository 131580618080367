import React from "react";

import styles from "../styles.module.scss";

import PercentageCircle from "../../PercentageCircle/PercentageCircle";
import { useCountdown } from "../../../../utils/hooks/useCountDown";

export default function TimerContent({
  description,
  countDownStart,
  initialDate,
}: {
  description?: string;
  countDownStart: number;
  initialDate: number;
}) {
  const [days, hours, minutes, seconds, countDown] = useCountdown(initialDate);
  const range = initialDate - countDownStart;
  const percentage = Math.ceil(countDown / (range / 100));
  if (countDown <= 0) {
    return (
      <div className={styles.timer}>
        <PercentageCircle percentage={0} />
        <div className={styles.timerInfo}>
          {description && (
            <p className={styles.timerDescription}>{description}</p>
          )}
          <p className={styles.timerValue}>Время вышло</p>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.timer}>
      <PercentageCircle percentage={percentage} />
      <div className={styles.timerInfo}>
        <>
          {description && (
            <p className={styles.timerDescription}>{description}</p>
          )}
          <p className={styles.timerValue}>
            {minutes} мин {seconds < 10 ? "0" : ""}
            {seconds} сек
          </p>
        </>
      </div>
    </div>
  );
}
