import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";
import profileStyles from "../styles.module.scss";

import { Skeleton, Tooltip } from "@mui/material";
import { useAppDispatch } from "../../../store/hooks";
import {
  useChangeExecutorMutation,
  useGetExecutorQuery,
} from "../../../store/executor/executor.api";
import { ordersApi } from "../../../store/orders/orders.api";
import Text from "../../../components/typography/Text/Text";
import AppSwitch from "../../../components/shared/AppSwitch/AppSwitch";
import classNames from "classnames";

export default function C2CBlock() {
  const dispatch = useAppDispatch();
  const [isС2СEnabled, setС2СEnabled] = useState(true);
  const { data: executorData, isLoading: isExecutorLoading } =
    useGetExecutorQuery();
  const [changeExecutor] = useChangeExecutorMutation();

  const handleChange = async () => {
    setС2СEnabled((state) => !state);
    if (executorData?.data?.state) {
      try {
        await changeExecutor({
          accepts_card_to_card: !executorData.data.accepts_card_to_card,
        }).unwrap();
        dispatch(ordersApi.util.resetApiState());
      } catch {
        setС2СEnabled((state) => !state);
      }
    }
  };

  useEffect(() => {
    if (executorData?.data?.state) {
      setС2СEnabled(executorData?.data?.accepts_card_to_card);
    }
  }, [executorData]);

  function getTokenSwitchText() {
    if (isExecutorLoading) {
      return <Skeleton height={19} width={128} />;
    }
    if (isС2СEnabled) {
      return <Text type="accent">Переводы с карты на карту</Text>;
    }
    return <Text type="muted">Переводы с карты на карту</Text>;
  }

  function getTokenDescription() {
    if (isExecutorLoading) {
      return <Skeleton width={"100%"} height={42} />;
    }
    if (isС2СEnabled) {
      return "Включите, чтобы принимать заявки на выплаты по номеру карты";
    }
    return "Включите, чтобы принимать заявки на выплаты по номеру карты";
  }
  return (
    <div className={classNames(profileStyles.paper, styles.container)}>
      <div className={styles.tokenSwitchContainer}>
        <Tooltip title={isС2СEnabled ? "Отключить" : "Включить"}>
          <AppSwitch
            disabled={isExecutorLoading}
            checked={isС2СEnabled}
            onChange={handleChange}
          />
        </Tooltip>
        <p className={styles.tokenSwitchText}>{getTokenSwitchText()}</p>
      </div>
      <p className={styles.tokenDescription}>{getTokenDescription()}</p>
    </div>
  );
}
