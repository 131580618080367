import React, { useState } from "react";

import styles from "./styles.module.scss";

import { ReactComponent as EditIcon } from "../../../assets/icons/edit-05.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { motion } from "framer-motion";
import { useController, useForm } from "react-hook-form";
import CurrencyInput from "react-currency-input-field";
import classNames from "classnames";
import { Skeleton } from "@mui/material";
import {
  useGetBanksListQuery,
  useUpdatePayoutLimitMutation,
} from "../../../store/payoutLimits/payoutLimits.api";
import { transformCurrencyValue } from "../../../utils/helpers/transformCurrencyValue";
import Text from "../../../components/typography/Text/Text";
import { motionButtonProps } from "../../../utils/styles/motionSettings";
import { IPayoutLimitItem } from "../../../store/payoutLimits/payoutLimits.types";

const minAmount = 0;

interface IReserve {
  data: IPayoutLimitItem;
}

export default function Reserve({ data }: IReserve) {
  const [isEditMode, setEditMode] = useState(false);
  const [isSaved, setSaved] = useState(false);
  const [isError, setError] = useState(false);

  const [updatePayoutLimit, { isLoading }] = useUpdatePayoutLimitMutation();
  const { data: banksData, isLoading: isBanksLoading } = useGetBanksListQuery({
    regionId: 1,
  });
  let bank;
  if (banksData?.data) {
    bank = banksData.data.find((i) => i.alias === data.bank_name);
  }
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });
  const { field } = useController({
    name: "amount",
    control: control,
    rules: {
      required: "Поле не заполнено",
      min: {
        value: minAmount,
        message: `Введите сумму более ${transformCurrencyValue(minAmount)}`,
      },
    },
  });
  const { onChange, ...amountField } = field;

  async function onSubmit() {
    setError(false);
    const resp: any = await updatePayoutLimit({
      id: data.id,
      limit: watch("amount"),
    });
    if (resp?.data?.status === "ok") {
      setSaved(true);
      setEditMode(false);
    } else {
      setError(true);
    }
  }

  function getEditText() {
    if (isError) {
      return (
        <Text type="danger">
          <>Ошибка</>
        </Text>
      );
    }
    if (errors?.amount) {
      return (
        <Text type="danger">
          <>{errors?.amount?.message || " "}</>
        </Text>
      );
    }
    if (isSaved) {
      return <Text type="accent">{"Резерв сохранен"}</Text>;
    }
    return <Text type="muted">{"Введите новое значение"}</Text>;
  }

  const reserveLeft = (
    <>
      <div
        className={classNames(styles.reserveLeft, {
          [styles.error]: Boolean(errors?.amount),
        })}
      >
        <h4 className={styles.reserveTitle}>{getEditText()}</h4>
        {isEditMode ? (
          <div className={styles.reserveBalance}>
            <span className={styles.reserveCurrency}>RUB</span>
            <CurrencyInput
              className={styles.reserveAmount}
              defaultValue={data.limit}
              autoFocus
              onValueChange={(value) => onChange({ target: { value } })}
              {...amountField}
            />
          </div>
        ) : (
          <div className={styles.reserveBalance}>
            <span className={styles.reserveCurrency}>RUB</span>
            <div className={styles.reserveAmount}>
              {transformCurrencyValue(data.limit)}
            </div>
          </div>
        )}
      </div>
      {isEditMode ? (
        <motion.button
          {...motionButtonProps}
          className={classNames(styles.reserveRight, styles.accent)}
          type="submit"
          disabled={!isValid || isLoading}
        >
          <CheckIcon />
        </motion.button>
      ) : (
        <motion.button
          {...motionButtonProps}
          className={styles.reserveRight}
          onClick={() => {
            setEditMode(true);
            setSaved(false);
          }}
          type="button"
        >
          <EditIcon />
        </motion.button>
      )}
    </>
  );
  return (
    <div className={styles.paper}>
      <div className={styles.bank}>
        <div className={styles.bankLogoContainer}>
          {isBanksLoading ? (
            <Skeleton width={20} height={20} variant="circular" />
          ) : (
            <img src={bank?.logo} alt="bank" />
          )}
        </div>
        <p className={styles.bankLabel}>
          {isBanksLoading ? <Skeleton width={80} /> : `Банк ${bank?.name}`}
        </p>
      </div>
      {isEditMode ? (
        <form className={styles.reserve} onSubmit={handleSubmit(onSubmit)}>
          {reserveLeft}
        </form>
      ) : (
        <div className={styles.reserve}>{reserveLeft}</div>
      )}
    </div>
  );
}
